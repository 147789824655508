<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="flex flex-wrap p-fluid mt-4">
    <!-- <div class="col-12 md:col-12">
    <app-global-value-list-suggestions-selector label="Title"
      [placeholder]="'Enter Title'"
      [control]="formGroup?.controls?.subject"
      [viewMode]="fieldViewMode"
      [listName]="'Policy Sections'">
    </app-global-value-list-suggestions-selector>
  </div> -->
    <!-- <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.policyBodySectionTemplate.label' | translate: 'Policy Body Section Template'"
        [placeholder]="
          moduleFieldString + '.policyBodySectionTemplate.placeholder' | translate: 'Enter Policy Body Section Template'
        "
        [control]="policyBodySectionTemplateControl"
        [viewMode]="'create'"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [dataKey]="'code'"
        [customProjectionFields]="['code', 'subject', 'type', 'body']"
        [targetTypes]="['POLICY_BODY_SECTION_TEMPLATE']"
      >
      </app-target-code-selector>
    </div> -->
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Title"
        [placeholder]="'Enter Title'"
        [control]="formGroup?.controls?.subject"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Type'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="policySectionTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.type"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'body'"
        label="Section Body"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.body"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <!-- <div class="col-12 md:col-12">
    <app-text-editor [name]="'body'"
      label="Section Body"
      [placeholder]="'Enter Text...'"
      [control]="formGroup?.controls?.body"
      [viewMode]="fieldViewMode"
    [advanced]="true"></app-text-editor>
  </div> -->
    <!-- <div class="col-12 md:col-6">
  <app-text-editor
    [name]="'notes'"
    label="Notes"
    [placeholder]="'Enter Text...'"
    [control]="formGroup?.controls?.notes"
    [viewMode]="fieldViewMode"
    [advanced]="true"
  ></app-text-editor>
</div> -->
  </div>

  <div class="flex flex-row-reverse gap-2">
    <!-- <app-button *ngIf="fieldViewMode != 'view'" [action]="submitButtonAction"></app-button> -->
    @if (showSaveButton) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
    @if (showPolicySectionTemplateButton) {
      <app-button [action]="sectionTemplateButtonAction"></app-button>
    }
    <!-- <app-button *ngIf="fieldViewMode != 'view'" [action]="detailsButtonAction"></app-button> -->
    <app-button *ngIf="fieldViewMode != 'view' && showCancel" [action]="cancelButtonAction"></app-button>
  </div>
</form>
