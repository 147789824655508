import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, IAction, PolicyBodySection, PolicyBodySectionDto, getEnumOptions } from '@shared/classes';
import { BaseFormPopupAltComponent } from '@shared/components/misc/base-form-popup-alt/base-form-popup-alt.component';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { AppDialogService, ViewModeService } from '@shared/services';
import { CitationItemFormComponent } from 'app/modules/org-framework/citation/citation-item-form/citation-item-form.component';
import { CitationDataService } from 'app/modules/org-framework/service/data/citation-data.service';
import { PolicyBodySectionTemplateDataService } from 'app/modules/org-framework/service/data/policy-body-section-template-data.service';
// import { CitationItemFormComponent } from 'app/modules/authority-module/citation/citation-item-form/citation-item-form.component';
// import { CitationDataService } from 'app/modules/authority-module/services/data/citation-data.service';
import { PolicySectionTemplateSelectorComponent } from '../../../policy-body-section-template/policy-section-template-selector/policy-section-template-selector.component';
@Component({
  selector: 'app-policy-body-section-item-form',
  templateUrl: './policy-body-section-item-form.component.html',
  styleUrls: ['./policy-body-section-item-form.component.scss'],
})
export class PolicyBodySectionItemFormComponent extends BaseForm<PolicyBodySectionDto> implements OnInit {
  detailsButtonAction: IAction = {
    id: 1,
    label: 'Details',
    buttonType: 'button',
    command: this.onOpenEditDialog.bind(this),
    icon: 'pi pi-external-link',
    loading$: this.loadingState$,
    buttonStyle: 'outlined',
  };
  sectionTemplateButtonAction: IAction = {
    id: 2,
    label: 'Apply From Template',
    buttonType: 'button',
    command: this.onOpenSectionTemplateDialog.bind(this),
    icon: 'pi pi-external-link',
    loading$: this.loadingState$,
    buttonStyle: 'outlined',
  };
  @Input() mode: 'edit' | 'create' | 'view' = 'edit';
  @Input() showCancel: boolean = false;

  showPolicySectionTemplateButton = false;
  policyBodySectionTemplateControl = new FormControl(null);
  policySectionTypes = getEnumOptions(PolicyBodySection.TypeEnum);

  constructor(
    public viewModeService: ViewModeService,
    private appDialogService: AppDialogService,
    private requestService: CitationDataService,
    private sectionTemplateService: PolicyBodySectionTemplateDataService
  ) {
    super(viewModeService, 'POLICY_BODY_SECTION');
  }

  ngOnInit(): void {
    this.policyBodySectionTemplateControl.valueChanges.subscribe(x => {
      this.formGroup.patchValue({ ...x });
    })
  }

  getData() {
    return this.mode == 'create' ? this.getDataKeyValueFormat() : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
    if (data?.create) {
      this.showPolicySectionTemplateButton = true;
    }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      subject: new FormControl(null, Validators.required),
      body: new FormControl(null),
      parent: new FormControl(null),
      type: new FormControl(null, Validators.required),
      // frameworkRef: new FormControl(null, Validators.required),
      // citationType: new FormControl(null),
      // description: new FormControl(null),
      // notes: new FormControl(null),
      // authorityDocument: new FormControl(null),
      // parent: new FormControl(null)
    });
  }
  onOpenEditDialog() {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      'Policy Body Section Details',
      (res) => {
        if (res) {
          let resObj = {};
          res.forEach((element) => {
            resObj[element.key] = element.value;
          });
          this.formGroup.patchValue({ ...resObj });
        }
      },
      {
        data: {
          dynamicViewComponent: CitationItemFormComponent,
          dataService: this.requestService,
          filters: [],
          selectedRows: [],
          patchData: false,
          formData: this.formGroup.getRawValue(),
        },
      }
    );
  }

  onOpenSectionTemplateDialog() {
    this.appDialogService.showDialog(
      BaseFormPopupAltComponent,
      'Apply From Template',
      (res) => {
        if (res) {
          let resObj = {};
          res.forEach((element) => {
            resObj[element.key] = element.value;
          });
          this.formGroup.patchValue({ ...resObj });
        }
      },
      {
        data: {
          dynamicViewComponent: PolicySectionTemplateSelectorComponent,
          dataService: this.sectionTemplateService,
          filters: [],
          selectedRows: [],
          patchData: false,
          formData: null,
        },
      }
    );
  }
}
